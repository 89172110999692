<template>
  <div>
      <v-container>
          <v-row style="margin: 0px">
              <v-flex xs12 md12 sm12> 

                  <v-card elevation="2" style="border-radius: 3px">
                      <v-card-text style="text-align: center; padding: 25px">
                          <img :src="noPage" alt="" style="width: 200px;justify-content: center">
                          <div style="padding: 10px;margin-top: 30px ">
                              <h3 style="margin: 0px 0px 20px 0px;">404. Something's missing</h3>
                          <h4 style="font-weight: 500; margin-bottom: 10px">Page not found. </h4>
                          <v-btn color="primary" small @click="$router.go(-1)">Go Back</v-btn>
                          </div>
                              
                      </v-card-text>
                  </v-card>

              </v-flex>
          </v-row>
      </v-container>
  </div>
</template>

<script>

export default {
    computed: {
        noPage() {
            return require('../assets/no-page-found.png')
        }
    }
}
</script>

<style>

</style>